import React from 'react';

const Line = () => {
  return (
    <div
      style={{
        marginLeft: '15%',
        marginRight: '15%',
        marginTop: '5%',
        borderStyle: 'none none double none',
      }}></div>
  );
};
export default Line;
